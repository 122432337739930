<div class="container-costom p-0">
  <div class = "mt-5">
    <ds-tabs>
      <ds-tabs-label for="rights">{{'' | i18n: 'single_rights'}}</ds-tabs-label>
      <ds-tabs-content id="rights">
        <form class="col-12 d-flex  flex-column p-0"  ds-form [formGroup]="newRequestForm">
          <div class="col-12 d-flex p-0" style="max-height: 180px">
            <div class="col-3">
              <ds-form-field>
                <label ds-label for="rightRegion">Region:</label>
                <ds-select  formControlName="regionId" id="rightRegion" required="false" [options]="regionsOptions"></ds-select>
                <label class="required_identifier"  *ngIf="newRequestForm.controls['regionId'].touched && newRequestForm.controls['regionId'].hasError('required')">{{'' | i18n: 'select_region_msg'}}</label>
              </ds-form-field>
              <ds-form-field>
                <label ds-label for="rightAppS">{{'' | i18n: 'application'}}:</label>
                <ds-select id="rightAppS" required="false"  formControlName="applicationId"
                           [options]="applicationOptions"></ds-select>
                <label class="required_identifier"  *ngIf="newRequestForm.controls['applicationId'].touched && newRequestForm.controls['applicationId'].hasError('required')">{{'' | i18n: 'select_applications'}}</label>
              </ds-form-field>
            </div>
            <div class="col-3">
              <div class = "row">
                <label ds-label for="rightLocation" >{{'' | i18n: 'locations'}}:</label>
                <ds-select id="rightLocation"
                           formControlName="locations"
                           [options]="locationsOption"
                           (valueChange)="getSelectedLocations($event)">

                </ds-select>
                <label class="required_identifier"  *ngIf="newRequestForm.controls['locations'].touched && newRequestForm.controls['locations'].hasError('required')">{{'' | i18n: 'select_one_location_msg'}}</label>
              </div>
            </div>
            <div class="col-4">
              <div class="col-12 d-flex flex-row">
                <ds-form-field>
                  <label ds-label >{{'' | i18n: 'start_date'}} & {{'' | i18n: 'end_date'}} </label>
                  <ds-datepicker [config]="dateConfig">
                    <input ds-date  formControlName="startDate" required/>
                    <input ds-date  formControlName="endDate" required/>
                  </ds-datepicker>
                  <label class="required_identifier"  *ngIf="newRequestForm.controls['startDate'].touched && newRequestForm.controls['startDate'].hasError('required')">{{'' | i18n: 'start_date_msg'}}</label>
                  <label class="required_identifier"  *ngIf="!isDateValid3Days">Bitte Zeitraum überprüfen <3 Tage</label>
                  <label class="required_identifier"  *ngIf="!isDateValid90Days">Startdatum darf nicht mehr als 90 Tage in der Zukunft liegen</label>
                  <label class="required_identifier"  *ngIf="isDateValidEXT">Enddatum darf nicht mehr als 1 Jahr in der Zukunft liegen</label>
                  <label class="required_identifier"  *ngIf="isDateValidEMP">Enddatum darf nicht mehr als 3 Jahre in der Zukunft liegen</label>
                </ds-form-field>
              </div>
              <div class="col-12 p-0 d-flex flex-row">
                <ds-form-field class="col-12">
                  <label ds-label for="rightComment">{{'' | i18n: 'write_a_comment'}}:</label>
                  <textarea ds-input id="rightComment" required="false"  formControlName="comment"></textarea>
                  <label class="required_identifier"  *ngIf="newRequestForm.controls['comment'].touched && newRequestForm.controls['comment'].hasError('required')">{{'' | i18n: 'write_a_comment'}}</label>
                </ds-form-field>
              </div>
            </div>
            <div class="col-2 d-flex justify-content-start flex-column">
              <button class="green_b_standard mt-0"  (click)="saveSingleRightRequest()" [disabled]="!areDatesValid()">{{'' | i18n: 'save'}}</button>
              <button class="cancel_b_standard" (click)="onHideComponent()">{{'' | i18n: 'cancel'}}</button>
            </div>
          </div>
          <div class="col-12 p-0">
            <ds-accordion>
              <ds-accordion-item class="ds-no-motion" variant="slim" *ngFor="let category of filterCategories(selectedApplication?.applicationRightsCategories); trackBy: trackByCategoryId">
                {{ category.name}}
                <div content style="margin-top: -15px;">
                  <ds-form-field style="margin-left: 40px">
                    <ds-checkbox-group *ngFor="let right of category.rights; trackBy: trackByRightId; let i = index">
                      <input ds-input type="checkbox" [id]="right.id.toString() + i" [value]="right.id" (change)="toggleRight($event, right)" [checked]="selectedRightsIds.has(right.id)" required />
                      <label ds-label for="" style=" font-size: small !important; font-weight: normal !important;">
                        {{right.name}}
                        <span class="info-icon" (mouseover)="showToolTip(i)" (mouseleave)="hideTooltip(i)" *ngIf="right.rightsInfo">&#9432;
                        <span class="tooltip" [id]="'tooltip-' + i" *ngIf="visibleToolTips[i]"> {{right.rightsInfo}}</span>
                        </span>
                      </label>
                    </ds-checkbox-group>
                  </ds-form-field>
                </div>
              </ds-accordion-item>
            </ds-accordion>
          </div>
        </form>
      </ds-tabs-content>
    </ds-tabs>
  </div>
</div>
