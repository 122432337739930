import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {DsDatepickerConfiguration, minMaxDate, SelectListOption} from '@bmw-ds/components';
import {SweetAlertService} from 'src/app/service/feedback/SweetAlertService.service';
import {ApplicationHttpService} from 'src/app/service/http/application-http.service';
import {BusinessCategoryHttpService} from 'src/app/service/http/business-category-http.service';
import {LocationHttpService} from 'src/app/service/http/location-http.service';
import {LookupHttpService} from 'src/app/service/http/lookup-http.service';
import {RegionHttpService} from 'src/app/service/http/region-http.service';
import {RequestHttpService} from 'src/app/service/http/request-http.service';
import {UserHttpService} from 'src/app/service/http/user-http.service';
import RegionMinified from 'src/app/shared/model/requests/region-min';
import Request from 'src/app/shared/model/requests/request';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {NewRequestService} from '../../../service/helper-services/new-request.service';
import LocationMinified from '../../../shared/model/requests/location-min';
import ApplicationMinified from '../../../shared/model/applications/application-min';
import {Observable} from 'rxjs';
import RightMinified from '../../../shared/model/rights/right-min';
import ApplicationRightsCategoryMinified from '../../../shared/model/applications/application-rights-category-min';
import User from '../../../shared/model/user/user';
import Modal from "../../../shared/model/modal";
import {ConfirmationComponent} from "../../popup/confirmation/confirmation.component";
import {DialogService} from "../../../service/modal/dialog.service";
import RequestsNew from '../../../shared/model/requests/requests-new';


@Component({
  selector: 'app-new-single-rights',
  templateUrl: './new-single-rights.component.html',
  styleUrls: ['./new-single-rights.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class NewSingleRightsComponent implements OnInit {
  @Input()  isApprover: boolean;
  @Input()  userRequesting: User;
  @Input()  maxDateNumber: number;
  @Input()  userExistingRequests: RequestsNew[];
  @Output() hideComponent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() newRequestAdded: EventEmitter<boolean> = new EventEmitter<boolean>();

  newRequestForm = new FormGroup({
    regionId: new FormControl('', [Validators.required]),
    applicationId: new FormControl('', [Validators.required]),
    locations: new FormControl( '', [Validators.required]),
    rights: new FormControl( '', [Validators.required]),
    startDate: new FormControl('', [Validators.required]),
    endDate: new FormControl('', [Validators.required]),
    comment: new FormControl('', [Validators.required]),
  });
  selectedRegion: RegionMinified;
  selectedApplication: ApplicationMinified;
  regionsOptions: SelectListOption[];
  selectedRegionId: string;
  showRightsAccordion: boolean;
  selectedApplicationId: string;
  selectedRightsIds: Set<number> = new Set<number>();
  locationsOption: { id: number; label: string; selected: boolean }[];
  applicationOptions: SelectListOption[];
  regionsMinified: RegionMinified[];
  today: number = new Date().getDate() - 1;
  minDateValidation: Date;
  maxDateValidation: Date;
  dateConfig: Partial<DsDatepickerConfiguration> = {};
  dateDifference: number = 0;
  dateDifferenceTodayToStart: number = 0;
  isDateValid3Days: boolean = true;
  isDateValid90Days: boolean = true;
  isDateValidEMP: boolean = false;
  isDateValidEXT: boolean = false;
  maxDateYear: number = new Date().getFullYear();
  userSelectedLocation = [];
  requestMoreLocation = false;
  selectedRules = [];
  addMultipleLocation = [];
  tooltipText: string | null = null;
  visibleToolTips: { [key: number]: boolean } = {};
  constructor(private requestService: RequestHttpService, private sweetAlertService: SweetAlertService,
              private userHttpService: UserHttpService, private regionHttpService: RegionHttpService,
              private lookupHttpService: LookupHttpService, private locationHttpService: LocationHttpService,
              private businessCategoryHttpService: BusinessCategoryHttpService, private applicationHttpService: ApplicationHttpService,
              private newRequestService: NewRequestService, private dialog: DialogService) {
  }

  ngOnInit() {
    this.listenToFormChanges();
    this.buildRegionsOptions();

    this.minDateValidation = new Date(new Date().setDate(this.today));
    this.maxDateValidation = new Date(new Date().setFullYear(new Date().getFullYear() + 1));

    let formatter = new Intl.DateTimeFormat('de-DE', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    });
    formatter.format(this.minDateValidation);
    formatter.format(this.maxDateValidation);
    this.dateConfig = {
      disabledDatesFactory: [minMaxDate({
        min: this.minDateValidation,
        max: this.maxDateValidation
      })],
      allowDirectInput: false,
      selectionMode: 'range',
      closeOnDateSelect: true,
    };

  }

  private filteredCategories(region: RegionMinified) {
    const filteredCategories = region.applications.map(application => ({
      ...application,
      applicationRightsCategories: application.applicationRightsCategories ?
        application.applicationRightsCategories.map(category => ({
          ...category,
          rights: category.rights.filter(right => !right.hide)
        }))
          .filter(category => category.rights.length > 0)
        : []
    }));
    return {
      ...region,
      applications: filteredCategories
    };
  }

  listenToFormChanges(){
    this.newRequestForm.get('regionId')
      .valueChanges.pipe(debounceTime(200),
      distinctUntilChanged())
      .subscribe(regionId => {
        if (regionId){
          this.selectedRegion = this.regionsMinified.find(region => region.id === +regionId );
          if (this.selectedRegion) {
            this.selectedRegion = this.filteredCategories(this.selectedRegion);
          }
          this.selectedApplication = null;
          this.newRequestForm.get('applicationId').setValue(null);
          this.buildApplicationOptions(this.selectedRegion);
          this.buildLocationOptions(this.selectedRegion);
        }
      });

    this.newRequestForm.get('applicationId')
      .valueChanges.pipe(debounceTime(200),
      distinctUntilChanged())
      .subscribe(applicationId => {
        if (applicationId){
          this.selectedApplication = this.selectedRegion
            .applications.find(application => application.id === +applicationId );
          this.showRightsAccordion = true;

        }
      });

    this.newRequestForm.get('endDate').valueChanges.pipe(debounceTime(200),distinctUntilChanged()).subscribe(
      endDate => {
        if (endDate) {
          this.dateDifference = (endDate.getTime() - this.newRequestForm.get('startDate').value.getTime()) / (1000 * 3600 * 24);
          this.dateDifferenceTodayToStart = (this.newRequestForm.get('startDate').value.getTime() / (1000 * 3600 * 24) -
            new Date().getTime()  / (1000 * 3600 * 24) + 1);

          this.isDateValid3Days = this.dateDifference >= 4;
          this.isDateValid90Days = this.dateDifferenceTodayToStart < 90;
          this.isDateValidEMP = this.userRequesting.employeeType == 'EMP' && this.dateDifference > 1095;
          this.isDateValidEXT = this.userRequesting.employeeType == 'EXT' && this.dateDifference > 365;
        } else {
          this.isDateValid3Days = true;
          this.isDateValid90Days = true;
          this.isDateValidEMP = false;
          this.isDateValidEXT = false;
        }
      });

  }

  private buildRegionsOptions() {
    this.regionHttpService.getRegionsMinifiedForSingleRights().subscribe(regions => {
      this.regionsMinified = regions;
      const regionsWithApplications: RegionMinified[] = regions.filter(region => region.applications.length > 0);
      this.regionsOptions = regionsWithApplications.map(mapRegion => {
        let regionOption: SelectListOption;
        regionOption = {id: '' + mapRegion.id, label: mapRegion.name};
        return regionOption;
      })
        .sort((a, b) => a.label.localeCompare(b.label));
    });
  }

  private buildApplicationOptions(region: RegionMinified) {
    this.applicationOptions = region.applications.map(regionApplication => {
      let applicationOption: SelectListOption;
      applicationOption = {id: '' + regionApplication.id, label: regionApplication.name};
      return applicationOption;
    }).sort((a, b) => a.label.localeCompare(b.label));
  }

  filterCategories(categories: ApplicationRightsCategoryMinified[]): ApplicationRightsCategoryMinified[]{
    return categories?.filter(category => category?.rights.length !==  0)
      .sort((a, b) => a.name.localeCompare(b.name));
  }

  isRequiredSelectionValid(): boolean {
    return this.userSelectedLocation.length > 0 && this.selectedApplication.applicationRightsCategories != null
      && this.selectedApplication.applicationRightsCategories.length > 0;
  }

  saveSingleRightRequest() {
    this.getRights().subscribe(rights => {
      const request = this.newRequestService.getRequest(this.newRequestForm.value, this.selectedRegion,
        rights, null, this.isApprover, this.userRequesting);
      request.locations = this.userSelectedLocation;
      this.newRequestForm.markAllAsTouched();
      this.newRequestForm.markAsDirty();
      if (this.newRequestForm.valid) {
        this.saveRequest(request);
      }
    });
  }

  saveRequest(saveRequestDto: Request) {
    this.requestService.requestRights(saveRequestDto).subscribe(() => {
      if (!this.requestMoreLocation){
        this.newRequestAdded.emit(true);
        this.onHideComponent();
      }
    }, error => this.sweetAlertService.showError('Error.' + error.error));
  }

  getSelectedLocations(event){
    const selectedLocation = this.locationsOption.find(location => location.id === +event);
    if (selectedLocation) {
      this.userSelectedLocation = [selectedLocation];

      let selectedLocationRules = this.userExistingRequests.filter(req => {
        return req.locations.includes(this.userSelectedLocation[0].label)});

      this.selectedRules = [];
      selectedLocationRules.forEach(value => {
        if (value.type_enum == 'single_right') {
          this.selectedRules.push(value.rule);
        }
      });
    }
  }

  disableRule(rule: string):boolean {
    return this.selectedRules.includes(rule);
  }

  getRights(): Observable<RightMinified[]>{
    return new Observable<RightMinified[]>(observer => {
      const twoDRights: RightMinified[][] = this.selectedApplication?.applicationRightsCategories.map(category => category.rights);
      const rights: RightMinified[] = []?.concat(...twoDRights)?.filter(right => this.selectedRightsIds.has(right.id))
        .sort((a, b) => a.label.localeCompare(b.label));
      observer.next(rights);
    });

  }

  trackByCategoryId(index: number, category: ApplicationRightsCategoryMinified): number {
    return category.id;
  }

  trackByRightId(index: number, right: RightMinified): number {
    return right.id;
  }

  valueChange(ruleIds){
    this.newRequestForm.get('rights').markAllAsTouched();
    if (ruleIds.length !== 0){
      this.newRequestForm.get('rights').setValue(ruleIds);
    }
    else {
      this.newRequestForm.get('rights').setValue('');
    }

    this.selectedRightsIds = ruleIds.map(id => +id);
  }
  onHideComponent(){
    this.hideComponent.emit(false);
  }

  areDatesValid(): boolean {
    return this.isDateValid3Days == true && this.isDateValid90Days == true && this.isDateValidEMP == false && this.isDateValidEXT == false;
  }

  buildLocationOptions(region: RegionMinified){
    this.locationsOption = region.locations.map(location => {
      return { id: location.id, label: location.name, selected: true};
    }).sort((a, b) => a.label.localeCompare(b.label));
  }
  addMoreLocation() {
    if (this.newRequestForm.valid){
      const data: Modal = {
        action : 'request_more',
        message: 'request_more_message'
      };
      this.dialog.open(ConfirmationComponent, {data}).afterClosed().subscribe(results => {
        if (results){
          this.requestMoreLocation = true;
          this.addMultipleLocation.push(...this.userSelectedLocation);
          // this.saveBusinessRuleRequest();
          this.newRequestForm.get('locations').setValue('');
        }else{
          this.requestMoreLocation = false;
          this.addMultipleLocation.push(...this.userSelectedLocation);
          this.newRequestForm.get('locations').setValue(this.locationsOption);
          this.saveSingleRightRequest();
        } });
    }
  }

  toggleRight(event: any, right: RightMinified) {
    const isChecked = event.target.checked;
    if (isChecked) {
      this.selectedRightsIds.add(right.id);
    } else {
      this.selectedRightsIds.delete(right.id);
    }

    const rightControl = this.newRequestForm.get('rights');
    if (rightControl) {
      rightControl.setValue(Array.from(this.selectedRightsIds));
      // this.newRequestForm.get('right').setValue(Array.from(this.selectedRightsIds));
    }
  }
  showToolTip(index: number) {
    this.visibleToolTips[index] = true;
  }

  hideTooltip(index: number) {
    this.visibleToolTips[index] = false;
  }
}
