import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {DsDatepickerConfiguration, minMaxDate, SelectListOption} from '@bmw-ds/components';
import {SweetAlertService} from 'src/app/service/feedback/SweetAlertService.service';
import {RegionHttpService} from 'src/app/service/http/region-http.service';
import {RequestHttpService} from 'src/app/service/http/request-http.service';
import {UserHttpService} from 'src/app/service/http/user-http.service';
import RegionMinified from 'src/app/shared/model/requests/region-min';
import Request from 'src/app/shared/model/requests/request';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import ApplicationMinified from '../../../shared/model/applications/application-min';
import BusinessRuleMinified from '../../../shared/model/business-rules/business-rule-min';
import ApplicationRightsCategoryMinified from '../../../shared/model/applications/application-rights-category-min';
import {NewRequestService} from '../../../service/helper-services/new-request.service';
import LocationMinified from '../../../shared/model/requests/location-min';
import RightMinified from '../../../shared/model/rights/right-min';
import {Observable} from 'rxjs';
import User from '../../../shared/model/user/user';
import Modal from "../../../shared/model/modal";
import {ConfirmationComponent} from "../../popup/confirmation/confirmation.component";
import {UserRole} from "../../../shared/enums/user-role";
import {DialogService} from "../../../service/modal/dialog.service";
import RequestsNew from '../../../shared/model/requests/requests-new';

@Component({
  selector: 'app-new-business-rule',
  templateUrl: './new-business-rule.component.html',
  styleUrls: ['./new-business-rule.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class NewBusinessRuleComponent implements OnInit {
  @Input()  isApprover: boolean;
  @Input()  userRequesting: User;
  @Input()  maxDateNumber: number;
  @Input()  userExistingRequests: RequestsNew[];
  @Output() hideComponent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() newRequestAdded: EventEmitter<boolean> = new EventEmitter<boolean>();

  newRequestForm = new FormGroup({
    regionId: new FormControl('', [Validators.required]),
    businessCategoryId: new FormControl('', [Validators.required]),
    locations: new FormControl( '', [Validators.required]),
    rules: new FormControl('', [Validators.required]),
    startDate: new FormControl('', [Validators.required]),
    endDate: new FormControl('', [Validators.required]),
    comment: new FormControl('', [Validators.required]),
  });

  selectedRegion: RegionMinified;
  selectedRulesIds: number[];
  businessCategoryRules: BusinessRuleMinified[];
  businessCategoryOptions: SelectListOption[];
  regionsOptions: SelectListOption[];
  locationsOption: { id: number; label: string; selected: boolean }[];
  selectedRegionId: string;
  showBusinessRuleAccordion: boolean;
  applications: ApplicationMinified[];
  regionsMinified: RegionMinified[];
  today: number = new Date().getDate() - 1;
  minDateValidation: Date;
  maxDateValidation: Date;
  dateConfig: Partial<DsDatepickerConfiguration> = {};
  dateDifference: number = 0;
  dateDifferenceTodayToStart: number = 0;
  isDateValid3Days: boolean = true;
  isDateValid90Days: boolean = true;
  isDateValidEMP: boolean = false;
  isDateValidEXT: boolean = false;
  maxDateYear: number = new Date().getFullYear();
  userSelectedLocation = [];
  requestMoreLocation = false;
  selectedRules = [];
  addMultipleLocation = [];

  constructor(private requestService: RequestHttpService, private sweetAlertService: SweetAlertService,
              private userHttpService: UserHttpService, private regionHttpService: RegionHttpService,
              private newRequestService: NewRequestService, private dialog: DialogService) {
  }

  ngOnInit() {
    this.listenToFormChanges();
    this.buildRegionsOptions();

    this.minDateValidation = new Date(new Date().setDate(this.today));
    this.maxDateValidation = new Date(new Date().setFullYear(new Date().getFullYear() + 1));

    let formatter = new Intl.DateTimeFormat('de-DE', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    });
    formatter.format(this.minDateValidation);
    formatter.format(this.maxDateValidation);
    this.dateConfig = {
      disabledDatesFactory: [minMaxDate({
        min: this.minDateValidation,
        max: this.maxDateValidation
      })],
      allowDirectInput: false,
      selectionMode: 'range',
      closeOnDateSelect: true,
    };

  }

  listenToFormChanges(){
    this.newRequestForm.get('regionId')
      .valueChanges.pipe(debounceTime(200),
      distinctUntilChanged())
      .subscribe(regionId => {
        if (regionId){
          this.selectedRegion = this.regionsMinified.find(region => region.id === +regionId );
          this.businessCategoryRules = [];
          this.applications = this.selectedRegion.applications;
          this.newRequestForm.get('businessCategoryId').setValue(null);
          this.buildBusinessCategoryOptions(this.selectedRegion);
          this.buildLocationOptions(this.selectedRegion);
        }
      });

    this.newRequestForm.get('businessCategoryId')
      .valueChanges.pipe(debounceTime(200),
      distinctUntilChanged())
      .subscribe(businessCategoryId => {
        if (businessCategoryId){
          this.businessCategoryRules = [];
          const activeRulesIds: number[] = this.selectedRegion.businessRules.map( businessRule => businessRule?.id);
          const selectedBusinessCategory = this.selectedRegion.businessCategories.map(category => category)
            .find(selected => selected.id === +businessCategoryId);
          this.businessCategoryRules = selectedBusinessCategory.businessRules
            .filter(businessRule => activeRulesIds.includes(businessRule?.id));
          this.businessCategoryRules.sort((a, b) => a.name.localeCompare(b.name));
          this.applications.length > 0 ? this.showBusinessRuleAccordion = true : this.showBusinessRuleAccordion = false;
          this.buildAccordion(this.businessCategoryRules);
        }
      });

    this.newRequestForm.get('endDate').valueChanges.pipe(debounceTime(200),distinctUntilChanged()).subscribe(
      endDate => {
        if (endDate) {
          this.dateDifference = (endDate.getTime() - this.newRequestForm.get('startDate').value.getTime()) / (1000 * 3600 * 24);
          this.dateDifferenceTodayToStart = (this.newRequestForm.get('startDate').value.getTime() / (1000 * 3600 * 24) -
            new Date().getTime()  / (1000 * 3600 * 24) + 1);

          this.isDateValid3Days = this.dateDifference >= 4;
          this.isDateValid90Days = this.dateDifferenceTodayToStart < 90;
          this.isDateValidEMP = this.userRequesting.employeeType == 'BMW' && this.dateDifference > 1095;
          this.isDateValidEXT = this.userRequesting.employeeType == 'EXT' && this.dateDifference > 365;
        } else {
          this.isDateValid3Days = true;
          this.isDateValid90Days = true;
          this.isDateValidEMP = false;
          this.isDateValidEXT = false;
        }
      });
  }

  private buildRegionsOptions() {
    this.regionHttpService.getRegionsMinified().subscribe(regions => {
      this.regionsMinified = regions;
      const regionsWithBusinessCategories: RegionMinified[] = regions.filter(region => region.businessCategories.length > 0);
      this.regionsOptions = regionsWithBusinessCategories.map(mapRegion => {
        let regionOption: SelectListOption;
        regionOption = {id: '' + mapRegion.id, label: mapRegion.name};
        return regionOption;
      });
    });
  }

  buildBusinessCategoryOptions(region: RegionMinified){
    this.businessCategoryOptions = region.businessCategories.map(mapDisplayBusinessCategory => {
      let businessCategoryOption: SelectListOption;
      businessCategoryOption = {id: '' + mapDisplayBusinessCategory.id, label: mapDisplayBusinessCategory.name};
      return businessCategoryOption;
    }).sort((a, b) => a.label.localeCompare(b.label));
  }


  buildAccordion(businessRules: BusinessRuleMinified[]){
    businessRules.map(rule => {
      rule.selected = true;
      const appIds: number[] = rule.rights.map(right => right.applicationId);
      rule.applications = this.applications.filter(application => appIds.includes(application.id));
    });
  }
  filterRules(rules: BusinessRuleMinified[]): BusinessRuleMinified[]{
    return rules.filter(rule => rule.applications.length !== 0);
  }

  disableRule(rule: string):boolean {
    return this.selectedRules.includes(rule);
  }

  getApplicationCategories(categories: ApplicationRightsCategoryMinified[], rule: BusinessRuleMinified){
    const categoriesIds: number[] = rule.rights.map(right => right.applicationRightsCategoryId);
    if (categories) {
      return categories.filter(category => categoriesIds.includes(category.id));
    }
  }
  getApplicationCategoriesRights(categoryId: number, rights: RightMinified[]): RightMinified[]{
    return rights.filter(right => right.applicationRightsCategoryId === categoryId && right.active);
  }

  saveBusinessRuleRequest() {
    this.getBusinessRules().subscribe(rules => {
      const request = this.newRequestService.getRequest(this.newRequestForm.value, this.selectedRegion,
        null, rules, this.isApprover, this.userRequesting);
      request.locations = this.userSelectedLocation;
      this.newRequestForm.markAllAsTouched();
      this.newRequestForm.markAsDirty();
      if (this.newRequestForm.valid){
        this.saveRequest(request); }
    });
  }
  addMoreLocationPost() {

    const uniqueLocation = this.addMultipleLocation.filter(
      (value, index, self) =>
        index === self.findIndex((loc) => loc.id === value.id)
    );
    if (uniqueLocation.length > 0){
      uniqueLocation.forEach((location) => {
        this.getBusinessRules().subscribe(rules => {
          const request = this.newRequestService.getRequest(
            this.newRequestForm.value,
            this.selectedRegion,
            null,
            rules,
            this.isApprover,
            this.userRequesting);
          request.locations = [location];
          this.newRequestForm.markAllAsTouched();
          this.newRequestForm.markAsDirty();
          if (this.newRequestForm.valid){
            this.saveRequest(request); }
        });
      });
    }

  }

  saveRequest(saveRequestDto: Request) {
    this.requestService.requestRules(saveRequestDto).subscribe(() => {
      if (!this.requestMoreLocation){
        this.newRequestAdded.emit(true);
        this.onHideComponent();
      }
    }, error => this.sweetAlertService.showError('Error.' + error.error));
  }

  getSelectedLocations(event){
    const selectedLocation = this.locationsOption.find(location => location.id === +event);
    if (selectedLocation) {
      this.userSelectedLocation = [selectedLocation];
      let selectedLocationRules = this.userExistingRequests.filter(req => {
        return req.locations.includes(this.userSelectedLocation[0].label)});

      this.selectedRules = [];
      selectedLocationRules.forEach(value => {
        if (value.type_enum == 'business_rule') {
          this.selectedRules.push(value.rule);
        }
      });
    }
  }

  showApplication(app: any) {
    const appInRegion = this.selectedRegion.applications.find(value => value.id === app.id);
    return !!appInRegion;
  }

  onHideComponent(){
    this.hideComponent.emit(false);
  }

  valueChange(ruleIds){
    this.newRequestForm.get('rules').markAllAsTouched();
    if (ruleIds.length !== 0){
      this.newRequestForm.get('rules').setValue(ruleIds);
    }
    else {
      this.newRequestForm.get('rules').setValue('');
    }

    this.selectedRulesIds = ruleIds.map(id => +id);
  }

  getBusinessRules(): Observable<BusinessRuleMinified[]>{
    return new Observable<BusinessRuleMinified[]>(observer => {
      const selectedRules = this.businessCategoryRules?.filter(rule => this.selectedRulesIds.includes(rule.id));
      observer.next(selectedRules);
    });

  }

  areDatesValid(): boolean {
    return this.isDateValid3Days == true && this.isDateValid90Days == true && this.isDateValidEMP == false && this.isDateValidEXT == false;
  }

  isRequiredSelectionValid(): boolean {
    return this.userSelectedLocation.length > 0 && this.businessCategoryRules.length > 0;
  }

  buildLocationOptions(region: RegionMinified){
    this.locationsOption = region.locations.map(location => {
      return { id: location.id, label: location.name, selected: true};
    });
  }
  addMoreLocation() {
    if (this.newRequestForm.valid){
      const data: Modal = {
        action : 'request_more',
        message: 'request_more_message'
      };
      this.dialog.open(ConfirmationComponent, {data}).afterClosed().subscribe(results => {
        if (results){
          this.requestMoreLocation = true;
          this.addMultipleLocation.push(...this.userSelectedLocation);
          this.newRequestForm.get('locations').setValue('');
        }else{
          this.requestMoreLocation = false;
          this.addMultipleLocation.push(...this.userSelectedLocation);
          this.newRequestForm.get('locations').setValue(this.locationsOption);
          this.addMoreLocationPost();
          this.addMultipleLocation = [];
        } });
    }
  }

  protected readonly location = location;
}
